h2{
    font-family: 'Exo2';
    font-weight: 300;
    text-align: center;
    margin-top: 60px;
}

form{
    display: grid;
    justify-content: center;
}

.Authorization{
    font-family: 'Exo2';
    font-weight: 300;
    margin-top: 8vh;

    padding: 60px 80px 60px 80px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 12px;

    display: grid;
    justify-content: center;
    align-self: center;
    gap: 24px;
}

.Authorization p {
    margin-bottom: 4px;
}

.Authorization input{
    font-size: 18px;
    min-width: 400px;
    max-width: 800px;
    border-radius: 6px;
    height: 42px;

    border: 1px solid var(--second);

    padding-left: 14px;
}

.Authorization button{
    height: 42px;

    border-radius: 6px;
    background-color: var(--second);
    border: 0px;
    cursor: pointer;
}

.Authorization__message{
    text-align: center;
}

.red{
    color: red
}
.green{
    color: green;
}



.Authorization__statement{
    justify-self: center;
    font-size: 12px;
    font-weight: 100;
    opacity: 80%;
}


@media (max-width: 900px) {
    .Authorization{
        padding: 60px 30px 60px 30px;
        gap: 24px;
    }

    .Authorization input{
        min-width: 280px;
    }
}