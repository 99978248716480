.store {
  font-family: "Exo2";
  font-weight: 300;

  scroll-snap-align: start;
}

.store__filter {
  display: flex;
  align-items: center;
  gap: 20px;

  margin-left: 10vw;
  margin-bottom: 10px;
}

#Store {
  margin-bottom: 60px;
}

.store__filter button {
  cursor: pointer;
  display: none;
  width: 162px;
  background-color: var(--second);
  border: 0;
  height: 42px;
  border-radius: 6px;
}
.store__filter button:hover {
  opacity: 90%;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 100% 50%;

  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  appearance: none;
  outline-width: 0;

  border: 1px solid var(--second);
  border-radius: 10px;

  padding: 10px 10px 10px 5px;
  display: block;
  width: 10em;
  font-size: 1rem;
}

.store__container {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 20px;
}

/*  */

.store__container-close {
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 20px;
}

/* categories */

.store__container-categories-close {
  display: block;
}

.store__container-categories-close label {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.store__container-categories-close input {
  cursor: pointer;
}

.custom-checkbox {
  accent-color: var(--second);
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

.btn_close {
  background-color: brown;
  width: 242px;
}

.store__container-categories p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.store__container-categories div {
  display: flex;
  align-items: center;

  margin-bottom: 12px;
  margin-left: 8px;
  margin-right: 10px;
}

.store__container-categories label {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.store__container-categories input {
  cursor: pointer;
}

.store__batton {
  display: grid;
  justify-content: center;
}

.Btn-CardLoad {
  height: 52px;
  max-width: 400px;

  cursor: pointer;
  border-radius: 10px;
  border: 0px;

  background-color: rgba(255, 255, 255, 0);
  border: 1px solid var(--second);
  min-width: 244px;

  margin-top: 60px;
  margin-bottom: 80px;
}

.store__container-btnAddCard {
  margin-top: 32px;

  background-color: var(--second);
  border: 0px;
  border-radius: 6px;
  cursor: pointer;

  padding-left: 24px;
  padding-right: 24px;
  height: 32px;
}

@media (max-width: 1600px) {
  .Btn-CardLoad {
    margin-bottom: 60px;
  }
}

@media (max-width: 800px) {
  .store__filter {
    gap: 20px;
    margin-left: 2vw;
  }

  .store__filter button {
    display: block;
  }

  .store__container-close {
    grid-template-columns: 1fr;
  }

  /* categories */

  .store__container-categories-close {
    display: none;
  }
}
