.card{
    position: relative;

    border-radius: 10px;
    background: rgb(254, 250, 244);

    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10);

    padding: 4px;
    padding-bottom: 12px;
}

.card img{
    cursor: pointer;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.none{
    display: none;
}

.card-price {
    width: auto;

    padding-left: 8px;
    padding-right: 8px;

    height: 22px;
    background-color: #ffffff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10);

    border-radius: 10px;
    text-align: center;

    position: absolute;
    left: 16px;
    top: 20px;
}

.card-settings{

    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    right: 16px;
    top: 20px;

    border-radius: 50%;
}



.card-settings button{
    cursor: pointer;
    border-radius: 50%;
    border: 0px;
    height: 26px;
    width: 26px;

    text-align: center;

    padding: 0px;
}

@media (max-width: 800px) {
    .card-price {

        width: auto;
    
        padding-left: 18px;
        padding-right: 18px;
    
        height: 18px;

        font-size: 12px;
        background-color: #ffffff;
    
        border-radius: 10px;
        text-align: center;
    
        position: absolute;
        left: 16px;
        top: 20px;
    }

    .card-settings{
        height: 24px;
        width: 24px;
    }
}