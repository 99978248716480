.ViewWindow{
    position:fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.4);
}

.ViewWindow.active{
    display: flex;
    justify-content: center;
    align-items: center;
}


.ViewWindow__content{
    padding: 20px;
    background-color: var(--background);
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 60px;
    min-width: 200px;

    display: grid;
    justify-content: center;
    align-content: center;

    position: relative;

    gap: 20px;
}

.ViewWindow__content h2{
    margin: 0px;
    font-size: 18px;
}

.ViewWindow__content-img{
    height: auto;
    min-width: 140px;
    border-radius: 10px;
    border: 1px dashed #FFD6BC;
    background-color: var(--background);

    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */

    cursor: pointer;
    justify-self: center;

}

.ViewWindow__content-img img{
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    max-width: 300px;
    object-fit: cover;
}

.ViewWindow__content-buttonClose{
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 0px;

    cursor: pointer;
    background-color: var(--second);

    position: absolute;

    left: 16px;
    top: 16px;
}

.ViewWindow__content-img-input{
    position: relative;
    z-index: -99;
    height: 0px;
}


.ViewWindow__content-divider{
    width: 180px;
    height: 1px;
    background-color: var(--second);

    justify-self: center;
}

.ViewWindow__content form{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.ViewWindow__content form input{
    border: 1px solid var(--second);

    height: 28px;
    border-radius: 6px;
    padding-left: 10px;

    margin-top: 6px;

    width: 300px;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

#btnChangeCard{
    cursor: pointer;
    border: 0px;
    background-color: var(--second);

    height: 42px;
    border-radius: 6px;
    padding-left: 10px;
}

#btnDeleteCard{
    cursor: pointer;
    margin-top: 12px;
    border: 1px solid var(--second);
    background-color: var(--background);

    height: 42px;
    border-radius: 6px;
    padding-left: 10px;
}

@media (max-width: 800px) {
    .ViewWindow__content{
        padding: 20px;
        background-color: var(--background);
        border-radius: 10px;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 200px;
        gap: 10px;
    }

    .ViewWindow__content-img{
        max-height: 200px;
        min-width: 140px;
    }

    .ViewWindow__content-img img{
        max-height: 200px;
        max-width: 300px;
    }

    .ViewWindow__content-buttonClose{
        left: 6px;
        top: 6px;
    }

}